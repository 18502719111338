import HomeLayout from '../utils/Layouts/HomeLayout/HomeLayout';
import Banner from '../components/Home/Banner/Banner';
import CourseAbout from '../components/Home/CourseAbout/CourseAbout';
import HomeContact from '../components/Home/HomeContact/HomeContact';
import FreeCourse from '../components/Home/FreeCourse/FreeCourse';
import Partners from '../components/Home/Partners/Partners';
import HomeNews from '../components/Home/HomeNews/HomeNews';
import HomeLockedCourses from '../components/Home/HomeLockCourses/HomeLockCourses';

const Home = () => {
  return (
    <HomeLayout>
      <Banner />

      <CourseAbout />
      <HomeContact />

      <FreeCourse />
      <Partners />

      <HomeNews />

      <div className='onlineCourseHeading'>
        <h3 className='onlineCourseHeading__title'>Онлайн сургалт</h3>
      </div>
      <HomeLockedCourses type='advanced' maxCount={6} blocked />
    </HomeLayout>
  );
};

export default Home;
