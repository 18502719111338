import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import Layout from '../../utils/Layouts/Layout/Layout';
import Axios from '../../Axios';

const noIMG = require('../../assets/no-image.png');
const banner = require('../../assets/newshead.jpg');

const News = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios.get('/news/list').then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });
  }, []);

  /* ======== Item Height ======================================================= */
  const itemRef = useRef(null);
  const [itemHeight, setItemHeight] = useState(0);

  useEffect(() => {
    if (itemRef.current && data?.length > 0) {
      setItemHeight(itemRef.current.getBoundingClientRect().height);
    }
  }, [itemRef, data]);
  /* ============================================================================ */

  return (
    <Layout>
      <div className='news'>
        <img className='news__banner' src={banner} alt='no file' />

        <div className='news__content'>
          {Array.isArray(data) &&
            data.map((item) => (
              <div ref={itemRef} key={item.id} className='news__content-item'>
                <figure className='news__content-item-cover'>
                  <img src={item.cover ? item.cover : noIMG} alt='no file' />
                </figure>

                <p className='news__content-item-title'>{item.title}</p>

                <div className='news__content-item-content'>
                  <h3 className='news__content-item-content-title'>
                    {item.title}
                  </h3>
                  <div className='news__content-item-content-line' />

                  <div className='news__content-item-content-text'>
                    <TextTruncate
                      text={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.content.substring(0, 300) + '...',
                          }}
                        />
                      }
                      line={itemHeight > 320 ? 10 : 6}
                    />
                  </div>

                  <Link
                    className='news__content-item-content-detailLink'
                    to={`/news/${item.id}`}
                  >
                    Дэлгэрэнгүй
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default News;
