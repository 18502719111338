import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

import './layout.scss';

const Layout = ({ children }) => {
  return (
    <main className='layout'>
      <Header />
      <div className='layout__content'>{children}</div>
      <Footer />
    </main>
  );
};

export default Layout;
