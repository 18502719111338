import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from '../../../Axios';
import { Carousel, Slide } from '../../../ui/Carousel/Carousel';

import './homeLockCourses.scss';

const noFile = require('../../../assets/no-file.png');
const noIMG = require('../../../assets/no-image.png');
const banner = require('../../../assets/header.jpg');
const lockCourse = require('../../../assets/lock-course.png');

const HomeLockCourses = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios.get('/courses/0/list').then((res) => {
      if (res.status === 200 && res.data?.length > 0) {
        setData(res.data.slice(0, 6));
      }
    });
  }, []);

  return (
    <div className='homeLockCourses'>
      <img className='homeLockCourses__banner' src={banner} alt='no file' />

      <div className='homeLockCourses__heading'>
        <b className='homeLockCourses__heading-title'>Хичээлүүд</b>

        <span className='homeLockCourses__heading-sort'>
          Ангилал:
          <select>
            <option>Нийт</option>
          </select>
        </span>
      </div>

      <Carousel spaceBetween={20} slideCount={3} autoplay infinite>
        {Array.isArray(data) &&
          data.map((item, idx) => (
            <Slide key={idx} spaceBetween={20}>
              <div key={item.id} className='homeLockCourses__course'>
                <Link className='detailText' to='/courses/advanced/list'>
                  {'Дэлгэрэнгүй >>'}
                </Link>

                <figure className='lockFigure'>
                  <img src={lockCourse} alt='no file' />
                </figure>

                <figure className='homeLockCourses__course-cover'>
                  <img
                    src={item?.cover ? item.cover : noIMG}
                    alt='no file'
                    onError={(event) => {
                      event.target.onerror = null;
                      event.target.src = noFile;
                    }}
                  />
                </figure>

                <h3 className='homeLockCourses__course-title'>{item.title}</h3>
              </div>
            </Slide>
          ))}
      </Carousel>
    </div>
  );
};

export default HomeLockCourses;
