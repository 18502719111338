import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../utils/Layouts/Layout/Layout";
import Modal from "../../utils/Modal/Modal";

const pdfLink = require("../../assets/IOTB-ТЭТГЭЛЭГ.pdf");
const picture1 = require("../../assets/tetgeleg.jpg");

function Scholarship() {
  const navigate = useNavigate();

  const [alertVisible, setAlertVisible] = useState(false);

  return (
    <Layout>
      <Modal
        visible={alertVisible}
        messageType="success"
        messageText="Таны тэтгэлгийн удирдамж татах эрх удахгүй нээгдэнэ"
        onOk={() => navigate("/scholarship")}
        onCancel={() => setAlertVisible(false)}
      />

      <div className="scholarship">
        <div className="scholarship__top">
          <figure className="scholarship__top-cover">
            <img
              src={picture1}
              className="scholarship__top-cover-img"
              alt="no file"
            />
          </figure>

          <div className="scholarship__top-decs">
            <h3 className="scholarship__top-decs-title">
              Тэтгэлэгт хөтөлбөр 2024-2025
            </h3>

            <p className="scholarship__top-decs-text">
              Эдийн засаг, санхүүгийн чиглэлээр суралцаж буй 2-оос 4-р дамжааны
              оюутнууд хамрагдана. Ирээдүйд олон улсын санхүүгийн зах зээлд
              өрсөлдөх чин хүсэл эрмэлзэлтэй, эх оронч иргэнийг дэмжихэд оршино.
            </p>

            <div className="scholarship__top-decs-btns">
              <a href={pdfLink} download="IOTB - ТЭТГЭЛЭГ">
                Тэтгэлгийн удирдамж татах
              </a>

              <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fform.jotform.com%2F241471804461453%3Ffbclid%3DIwZXh0bgNhZW0CMTAAAR0V3Rm1B-Jo0b6JT-9rVJaGM9yO66TGH0gga2gkGznJNzRQt4LhKyGF0Tw_aem_AZ3e6Pqwu5EVbJqBLF1m4Cuwg3TNe-i7yb_2s_TWeQqQnjLfUlIfEpcT8pLJ9QDkC84JyP7yfayhPxwl8iKfvAVu&h=AT07VNFyR6orQ-lmgSOmy7VKb0fRwl_lUglvzxpTiSvSkaLbWGoPxPx0OAkS02bfwM9KLbRSYsU9oloNyzLl6sAp7K7iarehRXDJh3u6QpdroNHgbV_CrshK3_mG7MOR89Y75pm-Xc_aHBXcdpOdpw">
                Тэтгэлэгт хүсэлт өгөх
              </a>
            </div>
          </div>
        </div>

        <div className="scholarship__bottom">
          <div className="scholarship__bottom-info">
            <b className="scholarship__bottom-info-title">
              Тэтгэлэгийн хэмжээ:
            </b>
            <picture1 className="scholarship__bottom-info-item">
              -&nbsp;&nbsp;&nbsp; 1,000,000 төгрөг
            </picture1>
            <p className="scholarship__bottom-info-item">
              -&nbsp;&nbsp;&nbsp; 1,700,000 төгрөгийн үнэ бүхий Олон улсын
              валютын зах зээлийн онлайн сургалтын эрх
            </p>

            <b className="scholarship__bottom-info-title">
              Тэтгэлэг хүсэгчид тавигдах үндсэн шалгуур:
            </b>
            <p className="scholarship__bottom-info-item">
              -&nbsp;&nbsp;&nbsp; Монгол улсын магадлан итгэмжлэгдсэн их, дээд
              сургуулийн бакалаврын зэргийн 2,3,4 -р курсын оюутан байх
            </p>
            <p className="scholarship__bottom-info-item">
              -&nbsp;&nbsp;&nbsp; Сурах хүсэл эрмэлзэлтэй, анги хамт олноо
              сурлагын амжилтаар тэргүүлэгч байх
            </p>
            <p className="scholarship__bottom-info-item">
              -&nbsp;&nbsp;&nbsp; 3.2 ба түүнээс дээш голчтой
            </p>
            <p className="scholarship__bottom-info-item">
              -&nbsp;&nbsp;&nbsp; Өрхийн орлого харгалзан үзнэ.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Scholarship;
